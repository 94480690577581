import React, { Component } from "react"
import Layout from "../components/layout"
import PageHeader from "../components/header/page-header"
import SEO from "../components/seo"
import CompanyNavMenu from "../components/menus/spyMenu"

import { graphql, Link, StaticQuery } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons"

import BackgroundImage from "gatsby-background-image"
import WhyChooseUsSection from "../components/whyChooseUsSection"

// import ReactImageVideoLightbox from "react-image-video-lightbox"

import { wordSlide } from "../components/animation/word-slide/word_slide"
class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lightBoxOpen: false,
    }
  }

  componentDidMount() {
    wordSlide()
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query About {
            bgImage: file(relativePath: { eq: "about/about-bg.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            coreValues: file(relativePath: { eq: "about/core-values-bg.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920, grayscale: false) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            member1: file(
              relativePath: { eq: "team/team_images/team_dhananja.png" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member2: file(
              relativePath: { eq: "team/team_images/team_dasun.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member3: file(
              relativePath: { eq: "team/team_images/team_hashadi.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member4: file(
              relativePath: { eq: "team/team_images/team_pathum.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member5: file(
              relativePath: { eq: "team/team_images/team_randika.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member6: file(
              relativePath: { eq: "team/team_images/team_wishvantha.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member7: file(
              relativePath: { eq: "team/team_images/team_chamodi.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member8: file(
              relativePath: { eq: "team/team_images/team_venuka.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member9: file(
              relativePath: { eq: "team/team_images/team_amal.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            member10: file(
              relativePath: { eq: "team/team_images/team_yuresha.jpg" }
            ) {
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
            placeholder: file(
              relativePath: { eq: "team/team_images/team_dhanushka.jpg" }
            ) {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
            team_prasad: file(
              relativePath: { eq: "team/team_images/team_prasad_01.jpg" }
            ) {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        `}
        render={data => {
          const companyRoutes = [
            {
              name: "Overview",
              route: "overview",
              offset: -10,
            },
            {
              name: "Our History",
              route: "history",
              offset: -50,
            },
            {
              name: "Core Values",
              route: "core-values",
              offset: -55,
            },
            // {
            //   name: "Our People",
            //   route: "our-people",
            //   offset: -60,
            // },
            {
              name: "Why Choose Us",
              route: "why-choose-us",
              offset: -50,
            },
          ]

          const teamData = [
            {
              name: "Dhananja Kariyawasam",
              role: "Chief Executive Officer",
              image: data.member1.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Chinthaka Weerakkodi",
              role: "Chief Technology Officer",
              image: data.member2.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Pathum Kariyawasam ",
              role: "Manager Operations",
              image: data.member4.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Randika Prabhashvara",
              role: "Tech Lead",
              image: data.member5.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Wishvantha Perera",
              role: "UI/UX Engineer ",
              image: data.member6.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Chamodi Bandara",
              role: "Project Manager",
              image: data.member7.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Venuka Wijethunga",
              role: "Associate UI/UX Engineer",
              image: data.member8.childImageSharp.fluid.src,
              twitter: "#",
              linkedin: "#",
              facebook: "#",
            },
            {
              name: "Amal Chathuranga",
              role: "Senior Development Engineer ",
              image: data.member9.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Dhanushka Prabhashwara",
              role: "Technical /Solutions Architect",
              image: data.placeholder.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
            {
              name: "Yuresha Madhushini",
              role: "Creative Designer",
              image: data.member10.childImageSharp.fluid.src,
              facebook: "#",
              twitter: "#",
              linkedin: "#",
            },
          ]

          const videoData = [
            {
              url: "https://www.youtube.com/watch?v=ia-0YsEGa6o",
              type: "video",
              altTag: "",
            },
          ]

          return (
            <Layout bgColor="#f4f4f9">
              {/* {this.state.lightBoxOpen && (
                <div
                  style={{
                    position: "fixed",
                    width: "100vw",
                    height: "100vh",
                    zIndex: "1000",
                    left: 0,
                    top: 0,
                  }}
                >
                  <ReactImageVideoLightbox
                    data={videoData}
                    showResourceCount={false}
                    onCloseCallback={() => {
                      this.setState({ lightBoxOpen: false })
                    }}
                  />
                </div>
              )} */}
              <SEO
                title="About | IT Solution company of the future"
                description="Your Trusted and Experienced IT Service Provider"
                metaImage={data.bgImage.childImageSharp.fluid}
              />
              <PageHeader
                className="about"
                title="About Us"
                thisPage="About"
                subtitle="Explorelogy is a pioneering IT services provider, advocating for aim based technology solutions for businesses. Our mission is to build customised, long-term solutions catering to company specific needs, that enable you to drive forward your business with ease, efficiency, and safety."
                buttonLink=""
                background={data.bgImage.childImageSharp.fluid}
              ></PageHeader>
              <CompanyNavMenu menuTitle="Company" routes={companyRoutes} />
              <div id="overview" className="container-fluid pt-4">
                <div className="col-md-5 mx-auto">
                  <h2
                    style={{ textAlign: "center", textTransform: "uppercase" }}
                  >
                    Your trusted and experienced IT service provider.
                  </h2>
                  <div style={{ height: 20 }}></div>

                  <p style={{ textAlign: "center" }}>
                    Explorelogy (Pvt) Ltd is a trusted and experienced IT
                    solution provider. We specialise in system integration and
                    engineering services including system development, cloud
                    infrastructure and consultation services. We implement
                    globally proven methodologies and technology, with a touch
                    of novelty and out-of-the-box thinking to provide client
                    specific IT remedies and end-to-end solutions.
                  </p>
                  <p style={{ textAlign: "center" }}>
                    This makes us the technological backbone for companies,
                    enabling them to perform with efficiency, convenience, and
                    improved functionality every step of the way. We are up to
                    date with the latest IT trends and approaches, ensuring that
                    companies can function in an increasingly digital age
                    focusing on their expertise without the hassle of
                    technological interruptions.
                  </p>
                  {/* <div style={{ height: 20 }}></div> */}
                  {/* <div className="row">
            <div className="col-md-4">
              <div className="text-center">
                <h2 className="counter-number align-items-center">
                  <CountUp end={200} />
                  <span
                    style={{ color: "#62748a", fontSize: 25, marginLeft: 10 }}
                  >
                    +
                  </span>
                </h2>
                Experts across a range of specializations
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <h2 className="counter-number align-items-center">
                  <CountUp end={30} />
                  <span
                    style={{ color: "#62748a", fontSize: 25, marginLeft: 10 }}
                  ></span>
                </h2>
                So far we have offices across 30 countries
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <h2 className="counter-number align-items-center">
                  <span
                    style={{ color: "#62748a", fontSize: 25, marginLeft: 10 }}
                  >
                    $
                  </span>
                  <CountUp end={15} />
                  <span
                    style={{ color: "#62748a", fontSize: 25, marginLeft: 10 }}
                  >
                    M
                  </span>
                </h2>
                $0 to $15M in revenue in under 3 years
              </div>
            </div>
          </div> */}
                </div>
              </div>
              <div className="container py-5 mt-5">
                <div className="row">
                  <div className="col-md-4 pb-3">
                    <div className="iconbox-about">
                      <div className="iconbox-content">
                        <img
                          alt="vision-icon"
                          src={"/images/about/locate-outline.svg"}
                          className="about-icon"
                        />
                        <h6>OUR VISION</h6>
                        <p>
                          To empower our clients with the latest, innovative
                          ideology to explore and face the digital competitive
                          market by deploying efficient and effective solutions
                          which contribute value-added services to the world
                          with leading-edge technology and we exist to
                          accelerate the progress of our customers, staying them
                          relevant to the digital economy and contributing
                          directly to their success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 pb-3">
                    <div className="iconbox-about">
                      <div className="iconbox-content">
                        <img
                          alt="mission-icon"
                          src={"/images/about/flag-outline.svg"}
                          className="about-icon"
                        />
                        <h6>OUR MISSION</h6>
                        <p>
                          To become the global leading digital company,
                          inspiring ordinary people to do the extraordinary in
                          accomplishing more innovative solutions strengthened
                          by passionate minds and upbringing trusted partnership
                          with our clients and commitment to the highest quality
                          of customer service provided with warmth,
                          friendliness, and personal pride.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 pb-3">
                    <div className="iconbox-about">
                      <div className="iconbox-content">
                        <img
                          alt="approach-icon"
                          className="about-icon"
                          src={"/images/about/layers-outline.svg"}
                        />
                        <h6>OUR APPROACH & CULTURE</h6>
                        <p>
                          Our expectation for an exceptional high quality
                          service delivery for our client chain. Mainly we want
                          to deliver unique products and services which increase
                          your brand value, market value and truly differentiate
                          your esteem organization from your competitors. Our
                          corporate culture expresses the core value and our
                          passion for helping you achieve your business goals
                          strategically through us.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container pb-5" id="history">
                <div className="col-md-6 mx-auto mb-5 pt-5 pb-3">
                  <h6
                    className="m-0"
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: 100,
                      letterSpacing: 4,
                    }}
                  >
                    OUR HISTORY
                  </h6>
                  <div style={{ height: 6 }}></div>
                  <h2
                    className="m-0"
                    style={{
                      textAlign: "center",
                      textTransform: "uppercase",
                      fontSize: "normal",
                      fontWeight: "100",
                    }}
                  >
                    DEVELOPMENT HISTORY OF Explorelogy
                  </h2>
                  <div style={{ height: 20 }}></div>
                  <div className="title-hr"></div>
                </div>
                <div className="col-md-8 mx-auto mb-5">
                  {companyHistory.map((item, index) => {
                    return (
                      <CompanyHistoryRow
                        key={index}
                        year={item.year}
                        description={item.description}
                      />
                    )
                  })}
                </div>
              </div>
              <BackgroundImage
                fluid={data.coreValues.childImageSharp.fluid}
                className="container-fluid px-0 core-values"
                id="core-values"
                Tag="div"
              >
                <div className="core-values-inner">
                  <div className="col-md-6 mx-auto">
                    <div>
                      <span
                        style={{
                          fontSize: "3rem",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        our values
                      </span>
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div style={{ position: "relative" }}>
                      <h1 className="cd-headline push">
                        <span> </span>
                        <span
                          className="cd-words-wrapper"
                          style={{ width: 470 }}
                        >
                          <b className="is-visible">EXPLORE</b>
                          <b className="is-hidden">INNOVATE</b>
                          <b className="is-hidden">ACCELERATE</b>
                        </span>
                      </h1>
                    </div>
                    <p
                      style={{
                        fontSize: "1.35rem",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      These values give us the foundations we need.
                    </p>
                    <div style={{ height: 20 }}></div>
                    <div
                      style={{ textAlign: "center" }}
                      className="button-video"
                    >
                      <a
                        href="/#"
                        onClick={() => {
                          this.setState({ lightBoxOpen: true })
                        }}
                        role="button"
                      >
                        <FontAwesomeIcon className="play-icon" icon={faPlay} />
                      </a>
                    </div>
                  </div>
                </div>
              </BackgroundImage>

              {/* <div className="container-fluid our-people" id="our-people">
                <div className="col-md-5 mx-auto">
                  <h6
                    className="m-0"
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: 100,
                      letterSpacing: 4,
                    }}
                  >
                    OUR PEOPLE
                  </h6>
                  <div style={{ height: 6 }}></div>
                  <h2
                    className="m-0"
                    style={{
                      textAlign: "center",
                      textTransform: "uppercase",
                      fontSize: "normal",
                      fontWeight: "100",
                    }}
                  >
                    Meet the team behind the scenes!
                  </h2>
                  <div style={{ height: 10 }}></div>
                  <p style={{ textAlign: "center" }}>
                    Products that work require people that work for it. Here is
                    the remarkable team behind the ideas, effort, and innovation
                    that drives our company forward.
                  </p>
                  <div style={{ height: 10 }}></div>

                  <div className="title-hr"></div>
                </div>
                <div style={{ height: 60 }}></div>

                <div className="col-md-10 mx-auto">
                  <div className="row">
                    {teamData.map((member, index) => (
                      <TeamMember
                        key={index}
                        memberName={member.name}
                        memberRole={member.role}
                        image={member.image}
                        facebook={member.facebook}
                        linkedin={member.linkedin}
                        twitter={member.twitter}
                      />
                    ))}
                  </div>
                </div>
                <div style={{ height: 60 }}></div>
                <div className="container-fluid">
                  <p style={{ textAlign: "center", marginBottom: 0 }}>
                    <Link className="link" to="/careers">
                      Now Hiring:
                    </Link>
                    &nbsp; Are you a driven and motivated Full Stack Developer?
                  </p>
                </div>
              </div> */}
              <WhyChooseUsSection />
              <div id="latest-insights"></div>
            </Layout>
          )
        }}
      ></StaticQuery>
    )
  }
}

const TeamMember = ({
  image,
  memberName,
  memberRole,
  facebook,
  twitter,
  linkedin,
}) => (
  <div className="col-6 col-md p-0 w-20">
    <div className="member-outer">
      <div className="member">
        <div className="member-content">
          <div className="member-image">
            <img src={image} alt="explorelogy team member" />
            {/* <Img className="image" fadeIn={true} fluid={image} /> */}
            <div className="social-icons-link">
              <a href={facebook}>
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              {/* <a href={twitter}>
                <FontAwesomeIcon icon={faTwitter} />
              </a> */}
              <a href={linkedin}>
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
          <div className="member-info">
            <h4 className="member-name">{memberName}</h4>
            <div className="member-subtitle">{memberRole}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const CompanyHistoryRow = ({ year, description }) => (
  <div className="row align-items-start py-4">
    <div className="col-md-4">
      <div className="text-center">
        <h4 style={{ margin: 0 }}>{year}</h4>
      </div>
    </div>
    <div className="col-md-8">
      <div>
        <p>{description}</p>
      </div>
    </div>
  </div>
)

const companyHistory = [
  {
    year: "2017 - 2018",
    description:
      "Explorelogy was first registered as a company on the 26th of December, 2017. This was the start of an incredible journey, exploring the latest resources, innovations, and developments in the IT industry, and providing solutions to empower and strengthen businesses.  ",
  },
  {
    year: "2018 - 2019",
    description:
      "Within a year Explorelogy launched the iconic EduPlus systems which would transform the future of education and student learning. By 2019, \n Explorelogy also formed a business partnership with NextVision (Pvt) Ltd, driving the company’s growth and expansion.",
  },
  {
    year: "2020 - Present",
    description:
      "In a move to be the most sought out IT solutions company, we partnered with Theewra Worldwide (Pvt) Ltd, now providing integrated and holistic IT services to all our customers. Together with Next Vision (Pvt) Ltd, in 2020, we launched an island wide IT solutions system for students known as Revision Live systems.",
  },
]
export default About
